import React from 'react';

import delightCustomers from '../../../assets/landing/delight-your-customer.webp';
import growYourBrand from '../../../assets/landing/grow-your-brand.webp';
import businessKickstart from '../../../assets/landing/kick-start-business.webp';

const ServicesAndBenefits: React.FC = () => {
  return (
    <>
      <section id="services" className="bg-white text-gray-800 pt-20 px-6">
        {/* Services Section */}
        <div className="max-w-7xl mx-auto">
          <div className="text-center mb-16">
            <h3 className="uppercase tracking-wide text-lg font-semibold mb-2 text-[#FF543E]">Services</h3>
            <h2 className="bri text-3xl md:text-4xl font-bold mt-2">
              Our services will bring your <br />
              business to the next level
            </h2>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
            {/* Service Cards */}
            {[
              {
                title: 'Kick-start your business',
                description: [
                  'Choose a domain address for your business',
                  'Create your website with our help in less than 15 mins',
                  'Set prices, offers, and availability'
                ],
                bgColor: 'bg-[#8B58E1]',
                image: businessKickstart
              },
              {
                title: 'Delight your customers',
                description: [
                  'Manage online and offline customers in one place',
                  'Accept online payments directly to your bank account',
                  'Store transaction and customer records'
                ],
                bgColor: 'bg-[#06BF79]',
                image: delightCustomers
              },
              {
                title: 'Grow your brand',
                description: [
                  'Manage all your business reviews in one place',
                  'Grow organic traffic with our SEO-friendly pages',
                  'Drive referrals for your business'
                ],
                bgColor: 'bg-[#FF7362]',
                image: growYourBrand
              }
            ].map((service, index) => (
              <div
                key={index}
                className={`p-6 rounded-xl shadow-lg text-white border-t-4 ${service.bgColor}`}
              >
                <img
                  src={service.image}
                  alt={service.title}
                  className="w-full h-72 object-cover rounded-lg mb-6"
                />
                <h3 className="bri text-xl font-bold mb-4">{service.title}</h3>
                <ul className="space-y-3">
                  {service.description.map((item, idx) => (
                    <li key={idx} className="int flex items-start">
                      <span className="text-green-500 mr-2">✔</span>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section id="benefits" className="max-w-7xl mx-auto pt-20">
        <div className="text-center mb-12">
          <h3 className="uppercase tracking-wide text-lg font-semibold mb-2 text-[#FF543E]">Benefits</h3>
          <h2 className="text-3xl md:text-4xl font-bold mt-2">
            Manage the business on the go. Your
            <br /> business is secure on our cloud
          </h2>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
          {/* Benefit Items */}
          {[
            {
              title: 'Custom Events Website',
              description:
                'All plans offer unlimited bandwidth and visitors. Focus on your design and content without worrying about visitor limits.',
              icon: '🌐'
            },
            {
              title: 'Comprehensive Booking System',
              description: 'Easily link your personal domain to our platform for a seamless experience.',
              icon: '📅'
            },
            {
              title: 'Secure Payment Processing',
              description: 'Add accessibility information into your webpages.',
              icon: '🔒'
            },
            {
              title: 'Real-Time Analytics Dashboard',
              description:
                'Everything you need for great SEO is available out-of-the-box like sitemaps, canonical URLs, and more.',
              icon: '📊'
            },
            {
              title: 'Integrated CRM',
              description: 'Add the analytics of your choice: Google Analytics, Fathom, Segment, and more.',
              icon: '📈'
            },
            {
              title: 'Automated Email Notifications',
              description: 'Integrate any third-party solution by adding embeds or custom code.',
              icon: '✉️'
            },
            {
              title: 'Reviews & Ratings',
              description: 'Supports light and dark modes in your designs via Figma variable modes.',
              icon: '⭐'
            }
          ].map((benefit, index) => (
            <div key={index} className="flex items-start gap-4 p-4 bg-gray-50 rounded-lg shadow-md">
              <span className="text-red-500 text-3xl">{benefit.icon}</span>
              <div>
                <h4 className="text-lg font-bold mb-2">{benefit.title}</h4>
                <p className="text-sm text-gray-600">{benefit.description}</p>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default ServicesAndBenefits;
