import React from 'react';

import { Button } from './sub/Button';
import laptopDesign from '../../../assets/landing/laptop-design.webp';

const Hero: React.FC = () => {
  return (
    <section id="home" className="relative pt-20 pb-4 bg-[#F0FDF8]">
      <div className="container mx-auto px-6 lg:px-16 text-center">
        {/* Title */}
        <h1 className="bri text-3xl sm:text-4xl lg:text-5xl font-bold leading-tight mb-4">
          Open your digital store & grow
          <span className="block">your business today</span>
        </h1>

        {/* Subtitle */}
        <p className="int text-base sm:text-lg lg:text-xl mx-auto mb-8 max-w-2xl">
          Everything you need to manage your business professionally and effortlessly - Website, Booking
          Engine, and Customer Engagements.
        </p>

        {/* Call-to-Action Button */}
        <div className="flex justify-center gap-4">
          <Button />
        </div>
      </div>

      {/* Responsive Image */}
      <img
        src={laptopDesign}
        alt={`Display preview`}
        className="w-full h-auto object-cover rounded-md mt-12 max-w-6xl mx-auto px-4 lg:px-0"
        onLoad={() => URL.revokeObjectURL(laptopDesign)}
      />
    </section>
  );
};

export default Hero;
