import * as yup from 'yup';

export const emailSchema = yup.object().shape({
  email: yup.string().required('Email is required.').email('Invalid Email')
});

export const loginSchema = emailSchema.shape({
  password: yup.string().required('Password is required.')
});

export const createUserSchema = emailSchema.shape({
  otp: yup
    .string()
    .required('OTP is required')
    .matches(/^\d{6}$/, 'OTP must be a 6-digit number'),
  password: yup
    .string()
    .min(8, 'Password should be minium 8 characters')
    .matches(/.*[0-9].*/, 'Password must include a number')
    .matches(/[^a-zA-Z0-9]/, 'Password must include a special charater')
    .required('Password is required.')
});

export type EmailData = yup.InferType<typeof emailSchema>;

export type LoginData = yup.InferType<typeof loginSchema>;
export type CreateUserData = yup.InferType<typeof createUserSchema>;
