import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const sidebarConfig = [
  {
    title: 'Operations',
    items: [
      { path: '/products', label: 'Products', iconClass: 'fa-box' },
      { path: '/bookings', label: 'Bookings', iconClass: 'fa-book' },
      { path: '/offline-tickets', label: 'Offline Tickets', iconClass: 'fa-ticket-alt' },
      { path: '/product', label: 'Add Product', iconClass: 'fa-plus-circle' },
      { path: '/payments', label: 'Payments', iconClass: 'fa-money-bill' }
    ]
  },
  // {
  //   title: 'Marketing',
  //   items: [
  //     { path: '/customers', label: 'Customers', iconClass: 'fa-users' },
  //     { path: '/social', label: 'Social', iconClass: 'fa-share-alt' },
  //     { path: '/reviews-ratings', label: 'Reviews & Ratings', iconClass: 'fa-star' }
  //   ]
  // },
  {
    title: 'General',
    items: [
      { path: '/business-details', label: 'Business Details', iconClass: 'fa-briefcase' },
      { path: '/website-details', label: 'Website Details', iconClass: 'fa-globe' },
      // { path: '/dashboard', label: 'Dashboard', iconClass: 'fa-tachometer-alt' },
      { path: '/bank-detail', label: 'Bank Details', iconClass: 'fa-plug' }
    ]
  }
];

const Sidebar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const activeScreen = React.useMemo(() => '/' + location.pathname.split('/')[1], [location.pathname]);

  const handleNavigation = (path: string) => {
    if (location.pathname !== path) {
      navigate(path);
    }
  };

  const SidebarButton: React.FC<{ path: string; label: string; iconClass: string }> = ({
    path,
    label,
    iconClass
  }) => (
    <button
      onClick={() => handleNavigation(path)}
      className={`flex items-center p-4 cursor-pointer w-full mt-2 ${
        activeScreen === path ? 'bg-black rounded text-white' : ''
      }`}
      aria-current={activeScreen === path ? 'page' : undefined}
    >
      <div className="flex items-center justify-center gap-2">
        <div className="flex justify-center items-center bg-[#35E0A1] h-[35px] rounded-full w-[35px]">
          <i className={`fas ${iconClass} text-black`} />
        </div>
        <span>{label}</span>
      </div>
    </button>
  );

  return (
    <aside className="w-1/5 sticky top-0 bg-zinc-100 overflow-y-auto font-medium max-h-screen">
      <nav className="pt-6 px-2 flex flex-col gap-4">
        {sidebarConfig.map(section => (
          <div key={section.title} className="mb-4">
            <h3 className="text-gray-500 text-left px-4">{section.title}</h3>
            <div>
              {section.items.map(item => (
                <SidebarButton
                  key={item.path}
                  path={item.path}
                  label={item.label}
                  iconClass={item.iconClass}
                />
              ))}
            </div>
          </div>
        ))}
      </nav>
    </aside>
  );
};

export default Sidebar;
