import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../hooks/store';
import { getBusinessDetailThunk, logoutPropThunk } from '../../redux/reducer/propSlice';

const Header: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const businessDetail = useAppSelector(state => state.prop.businessDetail);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    if (!businessDetail) {
      dispatch(getBusinessDetailThunk());
    }
  }, []);
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <header className="bg-white shadow-md flex justify-between">
      <Link to="/" className="bg-[#35e0a1] w-1/5 flex justify-center items-center">
        <p className="text-4xl font-bold tracking-wide">Zittle</p>
      </Link>
      <div className="relative mt-4 mb-4 mr-4">
        <button onClick={toggleDropdown} className="flex items-center bg-black text-white px-4 py-2 rounded">
          <div className="bg-[#35E0A1] flex items-center h-[30px] w-[30px] justify-center rounded-full mr-4">
            <i className="fas fa-map-marker-alt  text-black"></i>
          </div>
          <span>{businessDetail?.businessName ?? businessDetail?.email?.split('@')[0] ?? 'Owner'}</span>
          <i className="fas fa-chevron-down ml-4"></i>
        </button>
        {dropdownOpen && (
          <div className="absolute right-0 mt-2 w-48 bg-white shadow-lg rounded z-10">
            <a href="/privacy-policy" className="block px-4 py-2">
              Privacy Policy
            </a>
            <a href="/terms" className="block px-4 py-2">
              Terms & Conditions
            </a>
            <button
              className="block px-4 py-2 text-red-500"
              onClick={() => {
                dispatch(logoutPropThunk());
                navigate('/', { replace: true });
              }}
            >
              Logout
            </button>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
