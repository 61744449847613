import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';
import * as yup from 'yup';

import Button from '../../components/button/Button';
import InputSelect from '../../components/input/InputSelect';
import { useAppDispatch, useAppSelector } from '../../hooks/store';
import { getProductsThunk } from '../../redux/reducer/listingSlice';

const schema = yup.object().shape({
  product: yup.string().required('Product is required')
});

export interface FilterKey {
  listingId?: string;
}

const Filter = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const products = useAppSelector(state => state.listing.products);

  useEffect(() => {
    dispatch(getProductsThunk());
  }, [dispatch]);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = (data: { product: string }) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('listingId', data.product);
    navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
  };

  return (
    <div>
      <h3 className="font-semibold mt-4">Filter</h3>
      <form className="mt-4 space-y-4 font-medium" onSubmit={handleSubmit(onSubmit)}>
        <InputSelect
          options={products.map(({ id, name }) => ({ id, value: name }))}
          label="By product"
          placeholder="Select Product"
          register={register}
          errors={errors}
          name="product"
        />
        <Button
          type="submit"
          className="px-6 py-2 first-line:items-center  hover:bg-opacity-100 bg-opacity-50 transition duration-200 bg-[#35E0A1] text-black rounded-md"
          label="Filter"
        />
      </form>
    </div>
  );
};

export default Filter;
