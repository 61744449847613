/* eslint-disable jsx-a11y/label-has-associated-control */
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';

import LoadingSpin from '../../components/loading/LoadingSpin';
import { useAppDispatch, useAppSelector } from '../../hooks/store';
import { getBookingThunk } from '../../redux/reducer/propSlice';
import { BookingStatus } from '../../utils/entity/booking';
import { GetPropBookingByIdResponse } from '../../utils/models/booking';
import { getPrettyDateString } from '../../utils/utils';
import NotFound from '../NotFound';

const BookingsDetails: React.FC = () => {
  const { bookingId } = useParams();
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector(state => state.prop);
  const [booking, setBooking] = useState<GetPropBookingByIdResponse>();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (bookingId) {
        try {
          setBooking(await dispatch(getBookingThunk(bookingId)).unwrap());
        } catch (err) {
          navigate('/not-found');
        }
      }
    })();
  }, []);

  if (isLoading[getBookingThunk.pending.toString()]) {
    return <LoadingSpin />;
  }
  if (!booking) {
    return <NotFound />;
  }

  let bookingAmount = 0,
    discount = 0;

  booking?.tickets.forEach(({ count, meta }) => {
    const price = meta!.price * count;
    bookingAmount += price;
    discount += (price * meta!.discount) / 100;
  });
  return (
    <div className="ml-4 mt-2 font-medium">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Bookings Details</h1>
      </div>
      <div className="space-y-6 text-left">
        <div className="flex">
          <div className="w-4/5">
            <section className="mb-8">
              <h2 className="text-xl font-bold mb-4">Booking Id: {bookingId}</h2>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block font-bold">Customer Name</label>
                  <div className="relative">
                    <input
                      type="text"
                      value={booking.user.fullName}
                      disabled
                      className="bg-[#f6f6f6] mt-1 rounded p-2 w-full"
                    />
                    <Link
                      to={`/bookings?userId=${booking.userId}`}
                      className="absolute right-2 top-2 text-black underline font-medium"
                    >
                      View bookings
                    </Link>
                  </div>
                </div>
                <div>
                  <label className="block font-bold">
                    No. of Tickets
                    <input
                      type="text"
                      value={booking.tickets.length}
                      disabled
                      className="bg-[#f6f6f6] mt-1 rounded p-2 w-full"
                    />
                  </label>
                </div>
                <div>
                  <label className="block font-bold">
                    Phone Number
                    <input
                      type="text"
                      value={`+91 ${booking.user.phoneNumber}`}
                      disabled
                      className="bg-[#f6f6f6] mt-1 rounded p-2 w-full"
                    />
                  </label>
                </div>
                <div>
                  <label className="block font-bold">Transaction ID</label>
                  <input
                    type="text"
                    value={booking.transactionId}
                    disabled
                    className="bg-[#f6f6f6] mt-1 rounded p-2 w-full"
                  />
                </div>
                <div>
                  <label className="block font-bold">Booking Source</label>
                  <input
                    type="text"
                    value="Website"
                    disabled
                    className="bg-[#f6f6f6] mt-1 rounded p-2 w-full"
                  />
                </div>
                <div>
                  <label className="block font-bold">Payment Mode</label>
                  <input
                    type="text"
                    value="Online"
                    disabled
                    className="bg-[#f6f6f6] mt-1 rounded p-2 w-full"
                  />
                </div>
                <div>
                  <label className="block font-bold">Transaction Date</label>
                  <input
                    type="text"
                    value={dayjs(booking.createdAt).toDate().toLocaleDateString()}
                    disabled
                    className="bg-[#f6f6f6] mt-1 rounded p-2 w-full"
                  />
                </div>
                <div>
                  <label className="block font-bold">Product</label>
                  <input
                    type="text"
                    value={booking.meta?.listing.title}
                    disabled
                    className="bg-[#f6f6f6] mt-1 rounded p-2 w-full"
                  />
                </div>
                <div>
                  <label className="block font-bold">Booking Date</label>
                  <input
                    type="text"
                    value={getPrettyDateString(booking.sessionBatches[0])}
                    disabled
                    className="bg-[#f6f6f6] mt-1 rounded p-2 w-full"
                  />
                </div>
                <div>
                  <label className="block font-bold">Status</label>
                  <input
                    type="text"
                    value={booking.bookingStatus}
                    disabled
                    className="bg-[#f6f6f6] mt-1 rounded p-2 w-full"
                  />
                </div>
              </div>
            </section>
            {booking.bookingStatus === BookingStatus.PAID_ONLINE && (
              <section>
                <h2 className="text-xl font-bold mb-4">Payment Details</h2>
                <div className="grid grid-cols-1 gap-4">
                  <div>
                    <label className="block font-bold">Booking Amount</label>
                    <input
                      type="text"
                      value={`₹ ${bookingAmount}`}
                      disabled
                      className="bg-[#f6f6f6] mt-1 rounded p-2 w-6/12"
                    />
                  </div>
                  <div>
                    <label className="block font-bold">Discount</label>
                    <input
                      type="text"
                      value={`₹ ${discount}`}
                      disabled
                      className="bg-[#f6f6f6] mt-1 rounded p-2 w-6/12"
                    />
                  </div>
                  <div>
                    <label className="block font-bold">Tax Amount</label>
                    <input
                      type="text"
                      value="₹ 0"
                      disabled
                      className="bg-[#f6f6f6] mt-1 rounded p-2 w-6/12"
                    />
                  </div>
                  <div>
                    <label className="block font-bold">Total Amount Paid</label>
                    <input
                      type="text"
                      value={`₹ ${booking.totalAmount}`}
                      disabled
                      className="bg-[#f6f6f6] mt-1 rounded p-2 w-6/12"
                    />
                  </div>
                  <div>
                    <label className="block font-bold">Total Amount to be collected</label>
                    <input
                      type="text"
                      value="₹ 0"
                      disabled
                      className="bg-[#f6f6f6] mt-1 rounded p-2 w-6/12"
                    />
                  </div>
                </div>
                {/* <button className="bg-[#35e0a1] font-medium  px-10 py-2 rounded mt-4">Save</button> */}
              </section>
            )}
          </div>
          {/* <div className="flex flex-col items-end space-y-4 ml-8">
            <button className="bg-[#35e0a1]  px-2 py-2 rounded w-40 font-medium">Date Change</button>
            <button className="bg-[#35e0a1]  px-2 py-2 font-medium rounded w-40">Cancel Booking</button>
            <button className="bg-[#35e0a1] font-medium px-2 py-2 rounded w-40">Amount Paid</button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default BookingsDetails;
