import React from 'react';

import AboutUs from './subComponents/AboutUs';
import ContactUsForm from './subComponents/ContactUsForm';
import FAQSection from './subComponents/FAQSection';
import HeroSection from './subComponents/HeroSection';
import ServicesAndBenefits from './subComponents/ServicesAndBenefits';
import './subComponents/index.css';

import '@fontsource/bricolage-grotesque';
import '@fontsource/bricolage-grotesque/400.css';
import '@fontsource/inter';
import '@fontsource/inter/400.css';

const Landing: React.FC = () => {
  return (
    <div className="int scroll-smooth">
      <HeroSection />
      <ServicesAndBenefits />
      <AboutUs />
      <FAQSection />
      <ContactUsForm />
    </div>
  );
};

export default Landing;
