import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import LoadingSpin from '../../components/loading/LoadingSpin';
import { useAppDispatch, useAppSelector } from '../../hooks/store';
import { getBookingsThunk } from '../../redux/reducer/propSlice';
import { BookingStatus } from '../../utils/entity/booking';
import { getPrettyDateString } from '../../utils/utils';
import Filter, { FilterKey } from '../filter/Filter';

enum Tab {
  SUCCESS = 'Success',
  FAILED = 'Failed'
}

const SUCCESS_STATE = new Set([
  BookingStatus.PAID_ONLINE,
  BookingStatus.PAID_OFFLINE,
  BookingStatus.PAYMENT_PENDING
]);
enum SeachType {
  PHONE_NUMBER = 'PHONE_NUMBER',
  BOOKING_ID = 'BOOKING_ID'
}
const INPUT_MAP = {
  [SeachType.PHONE_NUMBER]: {
    placeholder: 'Enter Customer Phone Number',
    inputRegex: /^\d*$/,
    validationRegex: /\d{10}/,
    errorMessage: 'Phone Number should be of 10 digits'
  },
  [SeachType.BOOKING_ID]: {
    placeholder: 'Please Enter Booking Id',
    inputRegex: /^[0-9a-fA-F]*$/,
    validationRegex: /[0-9a-fA-F]{24}/,
    errorMessage: 'Invalid Booking Id'
  }
};
const Bookings: React.FC = () => {
  const { bookingMetas: bookings, isLoading } = useAppSelector(state => state.prop);
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const phoneNumber = searchParams.get('phoneNumber') ?? undefined;
  const bookingId = searchParams.get('bookingId') ?? undefined;
  const userId = searchParams.get('userId') ?? undefined;

  const listingId = searchParams.get('listingId') ?? undefined;

  const [selectedTab, setSelectedTab] = useState<Tab>(Tab.SUCCESS);
  useEffect(() => {
    const filters: FilterKey = { listingId };
    const key = { phoneNumber, bookingId, userId };

    if (key) {
      dispatch(getBookingsThunk({ ...key, ...filters }));
    }
  }, [searchParams]);

  const [searchType, setSeachType] = useState<SeachType>(
    bookingId ? SeachType.BOOKING_ID : SeachType.PHONE_NUMBER
  );
  const [searchValue, setSearchValue] = useState(phoneNumber ?? bookingId ?? '');
  const [isError, setIsError] = useState<boolean>(false);

  const handleDropdownChange = (e: { target: { value: string } }) => {
    const value = e.target.value as unknown as SeachType;
    setSeachType(value);
    setSearchValue('');
    setIsError(false);
  };

  const handleSearchInputChange = (e: { target: { value: string } }) => {
    const value = e.target.value;
    if (!INPUT_MAP[searchType].inputRegex.test(value)) {
      return;
    }
    setIsError(!INPUT_MAP[searchType].validationRegex.test(value));
    setSearchValue(value);
  };
  const onSearch = () => {
    if (INPUT_MAP[searchType].validationRegex.test(searchValue)) {
      setSearchParams(
        searchType === SeachType.BOOKING_ID ? { bookingId: searchValue } : { phoneNumber: searchValue }
      );
    } else {
      setIsError(true);
    }
  };

  const renderTableRows = () => {
    return bookings
      ?.filter(booking => {
        const isSuccess = SUCCESS_STATE.has(booking.bookingStatus);
        return selectedTab === Tab.SUCCESS ? isSuccess : !isSuccess;
      })
      .map((booking, i) => (
        <tr key={i} className="border-[1px] border-[#d9d9d9] font-medium">
          <td className="p-2 text-center cursor-pointer underline">
            <Link to={booking.id}>{booking.id}</Link>
          </td>
          <td className="p-2 text-black text-center">
            <Link to={`/bookings?userId=${booking.userId}`} className="underline font-medium">
              {booking.fullName}
            </Link>
          </td>
          <td className="p-2 text-center">{booking.listingName}</td>
          <td className="p-2 text-center">{booking.bookingStatus}</td>
          <td className="p-2 text-center min-w-[100px]">{getPrettyDateString(booking.sessionBatches[0])}</td>
        </tr>
      ));
  };

  return (
    <div className="flex flex-col">
      <h1 className="text-2xl font-bold mt-2">Bookings</h1>
      <main className="flex space-x-2">
        <div className="w-4/5">
          <div className="mb-8 mt-4">
            <div className="flex font-medium">
              <input
                type="text"
                placeholder={INPUT_MAP[searchType].placeholder}
                className="p-2 rounded-l w-1/3 bg-[#f6f6f6]"
                value={searchValue}
                onChange={handleSearchInputChange}
              />
              <select
                className="border p-2 rounded-r pl-6 bg-[#d9d9d9] pr-6"
                onChange={handleDropdownChange}
                value={searchType}
              >
                <option value={SeachType.PHONE_NUMBER}>Mobile No.</option>
                <option value={SeachType.BOOKING_ID}>Booking Id</option>
              </select>
              <button
                onClick={onSearch}
                disabled={isError}
                className="bg-[#35E0A1] text-black p-2 rounded  ml-4 pl-8 pr-8"
              >
                Search
              </button>
            </div>
            {isError && searchValue && (
              <p className="text-red-600 text-sm font-semibold mt-1">{INPUT_MAP[searchType].errorMessage}</p>
            )}
          </div>

          <div className="flex gap-4 my-4">
            {[Tab.SUCCESS, Tab.FAILED].map(tab => (
              <button
                key={tab}
                className={`px-4 py-2 rounded-md font-semibold ${
                  selectedTab === tab ? 'bg-green-500 text-white' : 'bg-gray-200 text-gray-700'
                }`}
                onClick={() => setSelectedTab(tab)}
              >
                {tab}
              </button>
            ))}
          </div>
          {/* <div className="flex justify-center mb-8">
            <div className="bg-[#f9f9f9] p-4 pt-6 rounded shadow w-1/5 h-[120px] text-center mx-2">
              <p className="text-2xl font-bold">₹ 499</p>
              <p className="font-medium text-lg mt-4">Total Revenue</p>
            </div>
            <div className="bg-[#f9f9f9] p-4 pt-6 rounded shadow w-1/5 text-center mx-2">
              <p className="text-2xl font-bold">145</p>
              <p className="font-medium text-lg mt-4">No. of Bookings</p>
            </div>
          </div> */}
          <table className="w-full rounded border-[1px] border-[#d9d9d9] shadow">
            <thead className="bg-[#d9d9d9] h-[60px] rounded-lg">
              <tr className="font-medium">
                <th className="p-2 text-center font-medium">Booking ID</th>
                <th className="p-2 text-center font-medium">Customer</th>
                <th className="p-2 text-center font-medium">Product</th>
                <th className="p-2 text-center font-medium">Status</th>
                <th className="p-2 text-center font-medium">Booking Date</th>
              </tr>
            </thead>
            <tbody>
              {isLoading[getBookingsThunk.pending.toString()] ? (
                <LoadingSpin />
              ) : bookings && bookings.length > 0 ? (
                renderTableRows()
              ) : (
                <div>No Bookings Found</div>
              )}
            </tbody>
          </table>
        </div>

        <div className="flex justify-end">
          <Filter />
        </div>
      </main>
    </div>
  );
};

export default Bookings;
