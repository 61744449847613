import './Login.css';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../hooks/store';
import { createPropThunk, loginPropThunk, propExistThunk } from '../../redux/reducer/propSlice';
import { withErrorHandler } from '../../utils/utils';
import {
  CreateUserData,
  createUserSchema,
  EmailData,
  emailSchema,
  LoginData,
  loginSchema
} from '../../validation/login';
import Button from '../button/Button';
import Input from '../input/Input';

enum LoginStep {
  ENTER_EMAIL = 'ENTER_EMAIL',
  EXISTING_USER_PASSWORD = 'EXISTING_USER_PASSWORD',
  NEW_USER_PASSWORD = 'NEW_USER_PASSWORD'
}

const COMPONENT_NAME = {
  [LoginStep.ENTER_EMAIL]: {
    buttonName: 'Continue',
    header: 'Get Started with your Email',
    schema: emailSchema
  },

  [LoginStep.EXISTING_USER_PASSWORD]: {
    buttonName: 'Login',
    header: 'Welcome back. \nEnter your password to login',
    schema: loginSchema
  },
  [LoginStep.NEW_USER_PASSWORD]: {
    buttonName: 'Create Password',
    header: 'Create your password',
    schema: createUserSchema
  }
};

const Login: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [step, setStep] = useState<LoginStep>(LoginStep.ENTER_EMAIL);
  const isAuthenticated = !!useAppSelector(state => state.prop.propId);
  const isLoading = useAppSelector(state => state.prop.isLoading);
  const location = useLocation();
  const from = location.state?.from?.pathname || '/products';

  if (isAuthenticated) {
    navigate(from, { replace: true });
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    resetField
  } = useForm<EmailData | LoginData | CreateUserData>({
    resolver: yupResolver(COMPONENT_NAME[step].schema)
  });

  const onSubmit = async (data: EmailData | LoginData | CreateUserData) => {
    await withErrorHandler(async () => {
      if (step === LoginStep.ENTER_EMAIL) {
        const { exist } = await dispatch(propExistThunk({ email: data.email })).unwrap();
        setStep(exist ? LoginStep.EXISTING_USER_PASSWORD : LoginStep.NEW_USER_PASSWORD);
      } else {
        await dispatch(
          step === LoginStep.NEW_USER_PASSWORD
            ? createPropThunk(data as CreateUserData)
            : loginPropThunk(data as LoginData)
        ).unwrap();

        navigate(from, { replace: true });
      }
    });
  };

  return (
    <div className="bg flex justify-end">
      <div className="bg-white w-1/3 mt-4 mr-4 mb-4 rounded-md flex flex-col justify-center p-6">
        <div className="mt-4">
          <p className="text-black font-medium">
            Welcome to <span className="text-[#35e0a1] text-3xl font-bold">Zittle</span>
          </p>
        </div>
        <div className="my-4">
          <h1 className="text-black text-2xl font-bold whitespace-pre-line tracking-wide">
            {COMPONENT_NAME[step].header}
          </h1>
        </div>
        <form className="flex flex-col mt-3" onSubmit={handleSubmit(onSubmit)}>
          {step === LoginStep.ENTER_EMAIL ? (
            <Input
              className="bg-[#f6f6f6] h-[35px] rounded-md px-2 font-medium text-black"
              type="email"
              placeholder="Enter Your Email"
              name="email"
              register={register}
              errors={errors}
            />
          ) : (
            <div className="space-y-2">
              <p className="font-medium">
                <span>{getValues('email')} </span>
                <button
                  className="text-[#35e0a1] underline"
                  onClick={() => {
                    setStep(LoginStep.ENTER_EMAIL);
                    resetField('password' as 'email');
                  }}
                  type="button"
                >
                  Change
                </button>
              </p>
              {step === LoginStep.NEW_USER_PASSWORD && (
                <Input
                  className="bg-[#f6f6f6] h-[35px] rounded-md px-2 font-medium text-black"
                  type="text"
                  placeholder="Enter OTP"
                  name="otp"
                  register={register}
                  errors={errors}
                />
              )}
              <Input
                className="bg-[#f6f6f6] h-[35px] rounded-md px-2 font-medium text-black"
                type="password"
                placeholder="Enter Your Password"
                name="password"
                register={register}
                errors={errors}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    const submitButton = document.querySelector('button[type="submit"]') as HTMLButtonElement;
                    submitButton?.click(); // Trigger the click
                  }
                }}
              />

              {step === LoginStep.NEW_USER_PASSWORD && (
                <div>
                  <p className="mt-2 font-medium">- Must include 8 characters</p>
                  <p className="font-medium">- Must include a number</p>
                  <p className="font-medium">- Must include a special character</p>
                </div>
              )}
            </div>
          )}

          <Button
            type="submit"
            className="bg-[#35e0a1] mt-6 h-[35px] rounded-md font-medium"
            label={COMPONENT_NAME[step].buttonName}
            isLoading={
              isLoading[propExistThunk.pending.toString()] ||
              isLoading[createPropThunk.pending.toString()] ||
              isLoading[loginPropThunk.pending.toString()]
            }
          />
        </form>

        {step === LoginStep.ENTER_EMAIL ? (
          <div>
            <div className="text-gray-300 mt-3 flex justify-center font-medium">or</div>
            <div className="text-gray-400 mt-3 flex justify-center bg-[#f6f6f6] mr-6 h-[35px] items-center font-medium">
              Continue With Google
            </div>
            <div className="mt-4 absolute bottom-6 ">
              <p className="font-medium">
                By continuing you agree to our
                <br />{' '}
                <a href="/privacy-policy" className="text-[#35e0a1]">
                  Privacy policies
                </a>{' '}
                and{' '}
                <a href="/terms" className="text-[#35e0a1]">
                  Terms & conditions
                </a>
              </p>
            </div>
          </div>
        ) : (
          <p className="flex justify-center font-medium gap-2 text-sm mt-2">
            Want to change your signup mode?{' '}
            <button className="text-[#35e0a1]" onClick={() => setStep(LoginStep.ENTER_EMAIL)}>
              Use another method
            </button>
          </p>
        )}
      </div>
    </div>
  );
};

export default Login;
