import './App.css';
import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';

import Login from './components/login/Login';
import AddProducts from './pages/addProducts/AddProducts';
import AddVenue from './pages/addProducts/AddVenue';
import AuthComponent from './pages/AuthComponent';
import BankDetail from './pages/bankDetial/BankDetail';
import Bookings from './pages/bookings/Bookings';
import BookingsDetails from './pages/bookings/BookingsDetails';
import BusinessDetails from './pages/businessDetails/BusinessDetails';
import Customers from './pages/customers/Customers';
import Dashboard from './pages/dashboard/Dashboard';
import Landing from './pages/landing/Landing';
import Footer from './pages/landing/subComponents/Footer';
import Navbar from './pages/landing/subComponents/Navbar';
import NotFound from './pages/NotFound';
import OfflineTickets from './pages/offlineTickets/OfflineTickets';
import Payments from './pages/payments/Payments';
import PrivacyPolicy from './pages/privacyPolicy/PrivacyPolicy';
import Products from './pages/products/Products';
import ProtectedLayout from './pages/ProtectedLayout';
import { Redirect } from './pages/Redirect';
import ReplyPage from './pages/reviewRatings/ReplyPage';
import ReviewsRatings from './pages/reviewRatings/ReviewsRatings';
import Social from './pages/social/Social';
import Terms from './pages/Terms/Terms';
import WebsiteDetails from './pages/websiteDetails/WebsiteDetails';
import { store } from './redux/store';

function App() {
  return (
    <Provider store={store}>
      <AuthComponent>
        <Toaster />
        <div className="block">
          <BrowserRouter>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/redirect" element={<Redirect />} />
              <Route
                element={
                  <div>
                    <Navbar />
                    <Outlet />
                    <Footer />
                  </div>
                }
              >
                <Route path="/" element={<Landing />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms" element={<Terms />} />
              </Route>
              <Route element={<ProtectedLayout />}>
                <Route path="/products" element={<Products />} />
                <Route path="/bookings" element={<Bookings />} />
                <Route path="/bookings/:bookingId" element={<BookingsDetails />} />
                <Route path="/offline-tickets" element={<OfflineTickets />} />
                <Route path="/payments" element={<Payments />} />
                <Route path="/product/:listingId?" element={<AddProducts />} />
                <Route path="/product/:listingId/venue/:venueId?" element={<AddVenue />} />
                <Route path="/customers" element={<Customers />} />
                <Route path="/social" element={<Social />} />
                <Route path="/reviews-ratings" element={<ReviewsRatings />} />
                <Route path="/reviews-ratings/reply-page" element={<ReplyPage />} />
                <Route path="/business-details" element={<BusinessDetails />} />
                <Route path="/website-details" element={<WebsiteDetails />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/bank-detail" element={<BankDetail />} />
              </Route>
              <Route path="*" element={<NotFound />} />
            </Routes>
          </BrowserRouter>
        </div>
      </AuthComponent>
    </Provider>
  );
}

export default App;
