import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="bg-black text-white py-14 px-6">
      <div className="max-w-7xl mx-auto flex flex-col md:flex-row justify-between gap-8">
        {/* Logo Section */}
        <div className="flex flex-col">
          <h1 className="bri text-5xl font-bold">Zittle</h1>
        </div>

        {/* Menu Section */}
        <div>
          <h3 className="text-lg font-semibold mb-4">Documents</h3>
          <ul className="space-y-2">
            <li>
              <a href="/terms" className="hover:underline">
                Terms
              </a>
            </li>
            <li>
              <a href="/privacy-policy" className="hover:underline">
                Privacy
              </a>
            </li>
          </ul>
        </div>

        {/* Contact Us Section */}
        <div>
          <h3 className="text-lg font-semibold mb-4">Contact Us</h3>
          <ul className="space-y-2">
            <li className="flex items-center gap-2">
              <span>📍</span> M&M elite, 4th Floor, Ayyappa Society, Madhapur, Hyderabad. 500081
            </li>
            <li className="flex items-center gap-2">
              <a href="mailto:sri.konda@zittle.in">
                <span>📧</span> sri.konda@zittle.in
              </a>
            </li>
            <li className="flex items-center gap-2">
              <a href="tel:+918333876209">
                <span>📞</span> +91-8333876209
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
