import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import LoadingSpin from '../../components/loading/LoadingSpin';
import { useAppDispatch, useAppSelector } from '../../hooks/store';
import { getBookingsWithTransactionThunk } from '../../redux/reducer/propSlice';
import { getPrettyDateString } from '../../utils/utils';

const Payments: React.FC = () => {
  const dispatch = useAppDispatch();
  const { bookingsWithTransaction: bookings, isLoading } = useAppSelector(state => state.prop);

  useEffect(() => {
    dispatch(getBookingsWithTransactionThunk());
  }, [dispatch]);

  let paymentAvailable = 0,
    payoutDone = 0;
  bookings?.forEach(booking => {
    const amt = booking.transaction.amount;
    if (!booking.transaction.paymentDate) {
      paymentAvailable += amt;
    } else {
      payoutDone += amt;
    }
  });

  const renderTableRows = () => {
    return bookings?.map((booking, i) => (
      <tr key={i} className="border-[1px] border-[#d9d9d9] font-medium">
        <td className="p-2 text-black text-center">
          <Link to={`/bookings?userId=${booking.userId}`} className="underline font-medium">
            {booking.fullName}
          </Link>
        </td>
        <td className="p-2 text-center cursor-pointer underline">
          <Link to={`/bookings/${booking.id}`}>{booking.id}</Link>
        </td>
        <td className="p-2 text-center">{booking.listingName}</td>
        <td className="p-2 text-center">{booking.transaction.paymentStatus ?? 'SETTLEMENT_PENDING'}</td>
        <td className="p-2 text-center min-w-[100px]">{getPrettyDateString(booking.sessionBatches[0])}</td>
      </tr>
    ));
  };

  const Card: React.FC<{ title: string; amt: number }> = ({ title, amt }) => (
    <div className="bg-gray-200 p-4 rounded shadow text-center">
      <p className="text-2xl font-bold">₹ {amt.toFixed(2)}</p>
      <p className="text-black text-sm md:text-base">{title}</p>
    </div>
  );
  return (
    <div className="my-5 w-10/12">
      <h1 className="text-2xl font-bold">Payments</h1>
      <div className="grid sm:grid-cols-1 md:grid-cols-3 gap-5 text-center my-4 max-w-md mx-auto">
        <Card title="Payment Available" amt={paymentAvailable} />
        <Card title="Total Payout Done" amt={payoutDone} />
        <Card title="Payment Pending" amt={paymentAvailable - payoutDone} />
      </div>

      <div className="overflow-x-auto rounded-md border">
        <table className="w-full rounded border-[1px] border-[#d9d9d9] shadow">
          <thead className="bg-[#d9d9d9] h-[60px] rounded-lg">
            <tr className="font-medium">
              <th className="p-2 text-center font-medium">Customer</th>
              <th className="p-2 text-center font-medium">Booking ID</th>
              <th className="p-2 text-center font-medium">Event</th>
              <th className="p-2 text-center font-medium">Payment Status</th>
              <th className="p-2 text-center font-medium">Booking Date</th>
              {/* <th className="p-2 text-center font-medium">Payment Mode</th> */}
            </tr>
          </thead>
          <tbody>
            {isLoading[getBookingsWithTransactionThunk.pending.toString()] ? (
              <LoadingSpin />
            ) : bookings && bookings.length > 0 ? (
              renderTableRows()
            ) : (
              <div>No Payments Found</div>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Payments;
