import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="m-5">
      <h1 className="text-3xl font-bold flex justify-center mb-6">Privacy Policy</h1>

      <p className="text-base mb-6">
        This Privacy Notice for Thurs Tech OPC Pvt Ltd (doing business as Zittle) (‘we’, ‘us’, or ‘our’),
        describes how and why we might access, collect, store, use, and/or share (‘process’) your personal
        information when you use our services (‘Services’), including when you:
      </p>

      <ul className="list-inside  mb-6">
        <li>
          - Visit our website at
          <a href="https://zittle.in/" className="text-blue-600">
            https://zittle.in/
          </a>
          , or any website of ours that links to this Privacy Notice
        </li>
        <li>
          - Use Zittle. Zittle helps event organizers create booking websites, manage ticket sales, and grow
          events without losing revenue to commissions.
        </li>
        <li>- Engage with us in other related ways, including any sales, marketing, or events</li>
      </ul>

      <p className="text-base mb-6">
        Questions or concerns? Reading this Privacy Notice will help you understand your privacy rights and
        choices. We are responsible for making decisions about how your personal information is processed. If
        you do not agree with our policies and practices, please do not use our Services. If you still have
        any questions or concerns, please contact us at
        <a href="mailto:sri.konda@zittle.in" className="text-blue-600">
          sri.konda@zittle.in
        </a>
        .
      </p>

      <h2 className="text-2xl font-semibold mb-4">Summary of Key Points</h2>
      <p className="py-2">
        This summary provides key points from our Privacy Notice, but you can find out more details about any
        of these topics by clicking the link following each key point or by using our table of contents below
        to find the section you are looking for.
      </p>
      <ul className="list-inside  mb-6">
        <li>
          <strong>-What personal information do we process?</strong> When you visit, use, or navigate our
          Services, we may process personal information depending on how you interact with us and the
          Services, the choices you make, and the products and features you use. Learn more about personal
          information you disclose to us.
        </li>
        <li>
          <strong>- Do we process any sensitive personal information?</strong>Some of the information may be
          considered ‘special’ or ‘sensitive’ in certain jurisdictions, for example, your racial or ethnic
          origins, sexual orientation, and religious beliefs. We do not process sensitive personal
          information.
        </li>
        <li>
          <strong>- Do we collect any information from third parties?</strong>We do not collect any
          information from third parties.
        </li>

        <li>
          <strong>How do we process your information?</strong> We process your information to provide,
          improve, and administer our Services, communicate with you, for security and fraud prevention, and
          to comply with law. We may process your information for other purposes with your consent.
        </li>
        <li>
          <strong>- In what situations and with which parties do we share personal information?</strong> We
          may share information in specific situations and with specific third parties. Learn more about when
          and with whom we share your personal information.
        </li>
        <li>
          <strong>How do we keep your information safe?</strong> We have adequate organisational and technical
          processes and procedures in place to protect your personal information. However, no electronic
          transmission over the internet or information storage technology can be guaranteed to be 100%
          secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorised third
          parties will not be able to defeat our security and improperly collect, access, steal, or modify
          your information. Learn more about how we keep your information safe.
        </li>
        <li>
          <strong>What are your rights?</strong>Depending on where you are located geographically, the
          applicable privacy law may mean you have certain rights regarding your personal information. Learn
          more about your privacy rights.
        </li>
        <li>
          <strong>- How do you exercise your rights? </strong>The easiest way to exercise your rights is by
          submitting a data subject access request, or by contacting us. We will consider and act upon any
          request in accordance with applicable data protection laws.
        </li>
      </ul>

      <h3 className="text-xl font-semibold mb-4">TABLE OF CONTENTS</h3>
      <ol className="list-decimal pl-6 mb-6">
        <li>
          <a href="#information" className="text-blue-600">
            WHAT INFORMATION DO WE COLLECT?
          </a>
        </li>
        <li>
          <a href="#processing" className="text-blue-600">
            HOW DO WE PROCESS YOUR INFORMATION?
          </a>
        </li>
        <li>
          <a href="#sharing" className="text-blue-600">
            WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
          </a>
        </li>
        <li>
          <a href="#cookies" className="text-blue-600">
            DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
          </a>
        </li>
        <li>
          <a href="#social-logins" className="text-blue-600">
            HOW DO WE HANDLE YOUR SOCIAL LOGINS?
          </a>
        </li>
        <li>
          <a href="#retention" className="text-blue-600">
            HOW LONG DO WE KEEP YOUR INFORMATION?
          </a>
        </li>
        <li>
          <a href="#security" className="text-blue-600">
            HOW DO WE KEEP YOUR INFORMATION SAFE?
          </a>
        </li>
        <li>
          <a href="#minors" className="text-blue-600">
            DO WE COLLECT INFORMATION FROM MINORS?
          </a>
        </li>
        <li>
          <a href="#privacy" className="text-blue-600">
            WHAT ARE YOUR PRIVACY RIGHTS?
          </a>
        </li>
        <li>
          <a href="#controls" className="text-blue-600">
            CONTROLS FOR DO-NOT-TRACK FEATURES
          </a>
        </li>
        <li>
          <a href="#update" className="text-blue-600">
            DO WE MAKE UPDATES TO THIS NOTICE?
          </a>
        </li>
        <li>
          <a href="#notice" className="text-blue-600">
            HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
          </a>
        </li>
        <li>
          <a href="#contact" className="text-blue-600">
            HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
          </a>
        </li>
        <li>
          <a href="#review" className="text-blue-600">
            HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
          </a>
        </li>
      </ol>

      <div id="information" className="mb-6">
        <h4 className="text-xl font-semibold mb-4">1. WHAT INFORMATION DO WE COLLECT?</h4>
        <ul>
          <strong>Personal information you disclose to us</strong>
          <li>
            <strong>In Short: We collect personal information that you provide to us.</strong>
          </li>
          <li>
            We collect personal information that you voluntarily provide to us when you register on the
            Services, express an interest in obtaining information about us or our products and Services, when
            you participate in activities on the Services, or otherwise when you contact us
          </li>
          <li>
            <strong>Personal Information Provided by You.</strong> The personal information that we collect
            depends on the context of your interactions with us and the Services, the choices you make, and
            the products and features you use. The personal information we collect may include the following:
          </li>
          <li>- Names</li>
          <li>- Email addresses</li>
          <li>- Phone numbers</li>
          <li>- Billing addresses</li>
          <li>- Mailing addresses</li>
          <li>- Job titles</li>
          <li>- Usernames</li>
          <li>- Passwords</li>
          <li>- Contact preferences</li>
          <li>- Contact or authentication data</li>
          <li>- Billing addresses</li>
          <li>
            <strong>Sensitive Information. </strong>We do not process sensitive information.
          </li>
          <li>
            <strong>Social Media Login Data.</strong> We may provide you with the option to register with us
            using your existing social media account details, like your Facebook, X, or other social media
            account. If you choose to register in this way, we will collect certain profile information about
            you from the social media provider, as described in the section called ‘HOW DO WE HANDLE YOUR
            SOCIAL LOGINS?’ below.
          </li>
          <li>
            All personal information that you provide to us must be true, complete, and accurate, and you must
            notify us of any changes to such personal information.
          </li>
          <li>
            <strong>Information automatically collected</strong>
          </li>
          <li>
            <strong>
              In Short: Some information — such as your Internet Protocol (IP) address and/or browser and
              device characteristics — is collected automatically when you visit our Services.
            </strong>
          </li>
          <li>
            We automatically collect certain information when you visit, use, or navigate the Services. This
            information does not reveal your specific identity (like your name or contact information) but may
            include device and usage information, such as your IP address, browser and device characteristics,
            operating system, language preferences, referring URLs, device name, country, location,
            information about how and when you use our Services, and other technical information. This
            information is primarily needed to maintain the security and operation of our Services, and for
            our internal analytics and reporting purposes.
          </li>
          <li>Like many businesses, we also collect information through cookies and similar technologies.</li>
          <li>The information we collect includes:</li>
          <li>
            <strong> - Log and Usage Data.</strong> Log and usage data is service-related, diagnostic, usage,
            and performance information our servers automatically collect when you access or use our Services
            and which we record in log files. Depending on how you interact with us, this log data may include
            your IP address, device information, browser type, and settings and information about your
            activity in the Services (such as the date/time stamps associated with your usage, pages and files
            viewed, searches, and other actions you take such as which features you use), device event
            information (such as system activity, error reports (sometimes called ‘crash dumps’), and hardware
            settings).
          </li>
          <li>
            <strong>- Device Data.</strong> We collect device data such as information about your computer,
            phone, tablet, or other device you use to access the Services. Depending on the device used, this
            device data may include information such as your IP address (or proxy server), device and
            application identification numbers, location, browser type, hardware model, internet service
            provider and/or mobile carrier, operating system, and system configuration information.
          </li>
          <li>
            <strong>- Location Data.</strong> We collect location data such as information about your device’s
            location, which can be either precise or imprecise. How much information we collect depends on the
            type and settings of the device you use to access the Services. For example, we may use GPS and
            other technologies to collect geolocation data that tells us your current location (based on your
            IP address). You can opt out of allowing us to collect this information either by refusing access
            to the information or by disabling your Location setting on your device. However, if you choose to
            opt out, you may not be able to use certain aspects of the Services.
          </li>
          <li>
            <strong>Google API</strong>
          </li>
          <li>
            Our use of information received from Google APIs will adhere to Google API Services User Data
            Policy, including the Limited Use requirements.
          </li>
        </ul>
      </div>

      <div id="processing" className="mb-6">
        <h4 className="text-xl font-semibold mb-4">2. HOW DO WE PROCESS YOUR INFORMATION?</h4>
        <ul>
          <li>
            In Short: We process your information to provide, improve, and administer our Services,
            communicate with you, for security and fraud prevention, and to comply with law. We may also
            process your information for other purposes with your consent
          </li>
          <li>
            We process your personal information for a variety of reasons, depending on how you interact with
            our Services, including:
          </li>
          <li>
            {' '}
            <strong>
              - To facilitate account creation and authentication and otherwise manage user accounts.
            </strong>{' '}
            We may process your information so you can create and log in to your account, as well as keep your
            account in working order.
          </li>
          <li>
            {' '}
            <strong>- To respond to user inquiries/offer support to users.</strong> We may process your
            information to respond to your inquiries and solve any potential issues you might have with the
            requested service.
          </li>
          <li>
            <strong>- To send administrative information to you.</strong> We may process your information to
            send you details about our products and services, changes to our terms and policies, and other
            similar information.
          </li>
          <li>
            <strong>- To fulfil and manage your orders.</strong> We may process your information to fulfil and
            manage your orders, payments, returns, and exchanges made through the Services.
          </li>
          <li>
            <strong>- To request feedback.</strong> We may process your information when necessary to request
            feedback and to contact you about your use of our Services.
          </li>
          <li>
            <strong>- To send you marketing and promotional communications.</strong> We may process the
            personal information you send to us for our marketing purposes, if this is in accordance with your
            marketing preferences. You can opt out of our marketing emails at any time. For more information,
            see “WHAT ARE YOUR PRIVACY RIGHTS?” below.
          </li>
          <li>
            <strong>- To post testimonials.</strong> We post testimonials on our Services that may contain
            personal information.
          </li>
          <li>
            <strong>- To evaluate and improve our Services, products, marketing, and your experience.</strong>{' '}
            We may process your information when we believe it is necessary to identify usage trends,
            determine the effectiveness of our promotional campaigns, and to evaluate and improve our
            Services, products, marketing, and your experience.
          </li>
          <li>
            <strong>- To identify usage trends.</strong> We may process information about how you use our
            Services to better understand how they are being used so we can improve them.
          </li>
          <li>
            <strong>- To determine the effectiveness of our marketing and promotional campaigns.</strong> We
            may process your information to better understand how to provide marketing and promotional
            campaigns that are most relevant to you.
          </li>
        </ul>
      </div>

      <div id="sharing" className="mb-6">
        <h4 className="text-xl font-semibold mb-4">
          3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
        </h4>
        <ul>
          <strong>
            In Short: We may share information in specific situations described in this section and/or with
            the following third parties.
          </strong>
          <li>We may need to share your personal information in the following situations:</li>
          <li>
            <strong>- Business Transfers.</strong> We may share or transfer your information in connection
            with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of
            all or a portion of our business to another company.
          </li>
          <li>
            <strong>- Affiliates. </strong>We may share your information with our affiliates, in which case we
            will require those affiliates to honour this Privacy Notice. Affiliates include our parent company
            and any subsidiaries, joint venture partners, or other companies that we control or that are under
            common control with us.
          </li>
          <li>
            <strong>- Business Partners.</strong> We may share your information with our business partners to
            offer you certain products, services, or promotions.
          </li>
        </ul>
      </div>

      <div id="cookies" className="mb-6">
        <h4 className="text-xl font-semibold mb-4">4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h4>
        <ul>
          <strong>
            In Short: We may use cookies and other tracking technologies to collect and store your
            information.
          </strong>
          <li>
            We may use cookies and similar tracking technologies (like web beacons and pixels) to gather
            information when you interact with our Services. Some online tracking technologies help us
            maintain the security of our Services and your account, prevent crashes, fix bugs, save your
            preferences, and assist with basic site functions.
          </li>
          <li>
            We also permit third parties and service providers to use online tracking technologies on our
            Services for analytics and advertising, including to help manage and display advertisements, to
            tailor advertisements to your interests, or to send abandoned shopping cart reminders (depending
            on your communication preferences). The third parties and service providers use their technology
            to provide advertising about products and services tailored to your interests which may appear
            either on our Services or on other websites.
          </li>
          <li>
            Specific information about how we use such technologies and how you can refuse certain cookies is
            set out in our Cookie Notice.
          </li>
          <strong>Google Analytics</strong>
          <li>
            We may share your information with Google Analytics to track and analyse the use of the Services.
            To opt out of being tracked by Google Analytics across the Services, visit
            <a href="" target="_blank" className="text-blue-600">
              https://tools.google.com/djpage/gaoptout
            </a>
            . For more information on the privacy practices of Google, please visit the Google Privacy & Terms
            page.
          </li>
        </ul>
      </div>

      <div id="social-logins" className="mb-6">
        <h4 className="text-xl font-semibold mb-4">5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</h4>
        <ul>
          <strong>
            In Short: If you choose to register or log in to our Services using a social media account, we may
            have access to certain information about you.
          </strong>
          <li>
            Our Services offer you the ability to register and log in using your third-party social media
            account details (like your Facebook or X logins). Where you choose to do this, we will receive
            certain profile information about you from your social media provider. The profile information we
            receive may vary depending on the social media provider concerned, but will often include your
            name, email address, friends list, and profile picture, as well as other information you choose to
            make public on such a social media platform.
          </li>
          <li>
            We will use the information we receive only for the purposes that are described in this Privacy
            Notice or that are otherwise made clear to you on the relevant Services. Please note that we do
            not control, and are not responsible for, other uses of your personal information by your
            third-party social media provider. We recommend that you review their privacy notice to understand
            how they collect, use, and share your personal information, and how you can set your privacy
            preferences on their sites and apps.
          </li>
        </ul>
      </div>

      <div id="retention" className="mb-6">
        <h4 className="text-xl font-semibold mb-4">6. HOW LONG DO WE KEEP YOUR INFORMATION?</h4>
        <strong>
          In Short: We keep your information for as long as necessary to fulfil the purposes outlined in this
          Privacy Notice unless otherwise required by law.
        </strong>
        <ul>
          <li>
            We will only keep your personal information for as long as it is necessary for the purposes set
            out in this Privacy Notice, unless a longer retention period is required or permitted by law (such
            as tax, accounting, or other legal requirements). No purpose in this notice will require us
            keeping your personal information for longer than the period of time in which users have an
            account with us.
          </li>
          <li>
            When we have no ongoing legitimate business need to process your personal information, we will
            either delete or anonymise such information, or, if this is not possible (for example, because
            your personal information has been stored in backup archives), then we will securely store your
            personal information and isolate it from any further processing until deletion is possible.
          </li>
        </ul>
      </div>

      <div id="security" className="mb-6">
        <h4 className="text-xl font-semibold mb-4">7. HOW DO WE KEEP YOUR INFORMATION SAFE?</h4>
        <strong>
          In Short: We aim to protect your personal information through a system of organisational and
          technical security measures.
        </strong>
        <ul>
          <li>
            We have implemented appropriate and reasonable technical and organisational security measures
            designed to protect the security of any personal information we process. However, despite our
            safeguards and efforts to secure your information, no electronic transmission over the Internet or
            information storage technology can be guaranteed to be 100% secure, so we cannot promise or
            guarantee that hackers, cybercriminals, or other unauthorised third parties will not be able to
            defeat our security and improperly collect, access, steal, or modify your information. Although we
            will do our best to protect your personal information, transmission of personal information to and
            from our Services is at your own risk. You should only access the Services within a secure
            environment.
          </li>
        </ul>
      </div>
      <div id="minors" className="mb-6">
        <h4 className="text-xl font-semibold mb-4">8. DO WE COLLECT INFORMATION FROM MINORS?</h4>
        <strong>
          In Short: We do not knowingly collect data from or market to children under 18 years of age.
        </strong>
        <li>
          We do not knowingly collect, solicit data from, or market to children under 18 years of age, nor do
          we knowingly sell such personal information. By using the Services, you represent that you are at
          least 18 or that you are the parent or guardian of such a minor and consent to such minor
          dependent’s use of the Services. If we learn that personal information from users less than 18 years
          of age has been collected, we will deactivate the account and take reasonable measures to promptly
          delete such data from our records. If you become aware of any data we may have collected from
          children under age 18, please contact us at{' '}
          <a href="" target="_blank" className="text-blue-600">
            sri.konda@zittle.in.
          </a>
        </li>
      </div>
      <div id="privacy" className="mb-6">
        <h4 className="text-xl font-semibold mb-4">9. WHAT ARE YOUR PRIVACY RIGHTS?</h4>
        <strong>
          In Short: You may review, change, or terminate your account at any time, depending on your country,
          province, or state of residence.
        </strong>
        <ul>
          <li>
            <strong> Withdrawing your consent:</strong> If we are relying on your consent to process your
            personal information, which may be express and/or implied consent depending on the applicable law,
            you have the right to withdraw your consent at any time. You can withdraw your consent at any time
            by contacting us by using the contact details provided in the section ‘HOW CAN YOU CONTACT US
            ABOUT THIS NOTICE?’ below.
          </li>
          <li>
            However, please note that this will not affect the lawfulness of the processing before its
            withdrawal nor, when applicable law allows, will it affect the processing of your personal
            information conducted in reliance on lawful processing grounds other than consent.
          </li>
          <li>
            <strong> Opting out of marketing and promotional communications:</strong> You can unsubscribe from
            our marketing and promotional communications at any time by clicking on the unsubscribe link in
            the emails that we send, replying ‘STOP’ or ‘UNSUBSCRIBE’ to the SMS messages that we send, or by
            contacting us using the details provided in the section ‘HOW CAN YOU CONTACT US ABOUT THIS
            NOTICE?’ below. You will then be removed from the marketing lists. However, we may still
            communicate with you — for example, to send you service-related messages that are necessary for
            the administration and use of your account, to respond to service requests, or for other
            non-marketing purposes.
          </li>
          <strong>Account Information</strong>
          <li>
            If you would at any time like to review or change the information in your account or terminate
            your account, you can:
          </li>
          <li>
            - Log in to your account settings and update your user account. - Contact us using the contact
            information provided.
          </li>
          <li>
            Upon your request to terminate your account, we will deactivate or delete your account and
            information from our active databases. However, we may retain some information in our files to
            prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms
            and/or comply with applicable legal requirements.
          </li>
          <li>
            <strong>Cookies and similar technologies:</strong> Most Web browsers are set to accept cookies by
            default. If you prefer, you can usually choose to set your browser to remove cookies and to reject
            cookies. If you choose to remove cookies or reject cookies, this could affect certain features or
            services of our Services.
          </li>
          <li>
            If you have questions or comments about your privacy rights, you may email us at
            <a href="" className="text-blue-600" target="_blank">
              sri.konda@zittie.in.
            </a>
          </li>
        </ul>
      </div>
      <div id="controls" className="mb-6">
        <h4 className="text-xl font-semibold mb-4">10. CONTROLS FOR DO-NOT-TRACK FEATURES</h4>
        <ul>
          <li>
            Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track
            (DNT) feature or setting you can activate to signal your privacy preference not to have data about
            your online browsing activities monitored and collected. At this stage, no uniform technology
            standard for recognising and implementing DNT signals has been finalised. As such, we do not
            currently respond to DNT browser signals or any other mechanism that automatically communicates
            your choice not to be tracked online. If a standard for online tracking is adopted that we must
            follow in the future, we will inform you about that practice in a revised version of this Privacy
            Notice.
          </li>
        </ul>
      </div>
      <div id="notice" className="mb-6">
        <h4 className="text-xl font-semibold mb-4">11. DO WE MAKE UPDATES TO THIS NOTICE?</h4>
        <strong>
          In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws.
        </strong>
        <ul>
          <li>
            We may update this Privacy Notice from time to time. The updated version will be indicated by an
            updated ‘Revised’ date at the top of this Privacy Notice. If we make material changes to this
            Privacy Notice, we may notify you either by prominently posting a notice of such changes or by
            directly sending you a notification. We encourage you to review this Privacy Notice frequently to
            be informed of how we are protecting your information.
          </li>
        </ul>
      </div>
      <div id="contact" className="mb-6">
        <h4 className="text-xl font-semibold mb-4">12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h4>
        <ul>
          <li>If you have questions or comments about this notice, you may contact us by post at:</li>
          <li>Thurs Tech OPC Pvt Ltd 13-37 Gudur, ANDHRA PRADESH 524101 India</li>
        </ul>
      </div>
      <div id="review" className="mb-6">
        <h4 className="text-xl font-semibold mb-4">
          13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
        </h4>
        <ul>
          <li>
            Based on the applicable laws of your country, you may have the right to request access to the
            personal information we collect from you, details about how we have processed it, correct
            inaccuracies, or delete your personal information. You may also have the right to withdraw your
            consent to our processing of your personal information. These rights may be limited in some
            circumstances by applicable law. To request to review, update, or delete your personal
            information, please fill out and submit a data subject access request.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
